<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <!-- Listeleme Kartı -->
    <b-card>
      <b-row class="mb-2">
        <b-col cols="4">
          <h4 class="card-title mb-0">Sorgu Listesi</h4>
        </b-col>
        <b-col cols="2">
          <b-button variant="dark" @click="runQuery()" block>
            SQL RUN
          </b-button>
        </b-col>
        <b-col cols="2">
          <b-button variant="danger" @click="testQuery()" block>
            Sorgu Testi
          </b-button>
        </b-col>
        <b-col cols="2">
          <b-button variant="danger" @click="showAddModal()" block>
            Yeni Sorgu Ekle
          </b-button>
        </b-col>
        <b-col cols="2">
          <b-button variant="success" @click="getQuery()" block>
            Listele
          </b-button>
        </b-col>
      </b-row>

      <b-table
        striped
        hover
        style="text-overflow: ellipsis"
        :items="queryList"
        :fields="tableFields"
        responsive
      >
        <template #cell(branchId)="data">
          {{ getBrancgTypeCode(data.item.branchId) }}
        </template>
        <template #cell(sqlQuery)="data">
          <div class="truncate-text">{{ data.item.sqlQuery }}</div>
        </template>
        <template #cell(actions)="data">
          <b-row>
            <b-col cols="6">
              <b-button
                size="sm"
                block
                variant="warning"
                @click="editQuery(data.item)"
              >
                Düzenle
              </b-button>
            </b-col>
            <b-col ols="6">
              <b-button
                size="sm"
                variant="danger"
                block
                @click="deleteQuery(data.item.id)"
              >
                Sil
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-card>

    <!-- Yeni Sorgu Ekle Modal -->
    <b-modal
      title="Yeni Sorgu Ekle"
      hide-footer
      v-model="addQueryModal"
      size="xl"
    >
      <b-form>
        <b-form-group label="Sorgu Anahtarı">
          <b-form-input
            v-model="query.key"
            placeholder="Sorgu Anahtarı"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Açıklama">
          <b-form-input
            v-model="query.description"
            placeholder="Açıklama"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Modül">
          <b-form-select id="Şube Seçin" v-model="query.branchId" required>
            <option
              v-for="branch in branchOptions"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.branchName }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="SQL Sorgusu">
          <AceEditor
            style="margin-left: 10px; margin-top: 1%; font-size: 15px"
            v-model="query.sqlQuery"
            height="350px"
            width="97%"
            lang="sql"
            theme="monokai"
            :options="{
              tabSize: 4,
              showPrintMargin: false,
            }"
          />
        </b-form-group>

        <b-form-group label="Yedek Sorgu">
          <b-form-textarea
            v-model="query.backupQuery"
            placeholder="Yedek Sorgu"
          ></b-form-textarea>
        </b-form-group>

        <b-button variant="primary" @click="queryAddSend">Kaydet</b-button>
        <b-button class="float-right" variant="secondary" @click="closeModal">
          İptal
        </b-button>
      </b-form>
    </b-modal>
    <!-- Update Sorgu  -->

    <b-modal
      title="Sorgu Güncelle"
      hide-footer
      v-model="updateQueryModal"
      size="xl"
    >
      <b-form>
        <b-form-group label="Sorgu Anahtarı">
          <b-form-input
            v-model="updateQuery.key"
            disabled
            placeholder="Sorgu Anahtarı"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Açıklama">
          <b-form-input
            v-model="updateQuery.description"
            placeholder="Açıklama"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Modül">
          <b-form-select
            id="Şube Seçin"
            v-model="updateQuery.branchId"
            required
          >
            <option
              v-for="branch in branchOptions"
              :key="branch.id"
              :value="branch.id"
            >
              {{ branch.branchName }}
            </option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="SQL Sorgusu">
          <AceEditor
            style="margin-left: 10px; margin-top: 1%; font-size: 15px"
            v-model="updateQuery.sqlQuery"
            height="50vh"
            width="97%"
            lang="sql"
            theme="monokai"
            :options="{
              tabSize: 4,
              showPrintMargin: false,
            }"
          />
        </b-form-group>

        <b-form-group label="Yedek Sorgu">
          <b-form-textarea
            v-model="updateQuery.backupQuery"
            placeholder="Yedek Sorgu"
          ></b-form-textarea>
        </b-form-group>

        <b-button variant="primary" @click="editSend">Güncelle</b-button>
        <b-button class="float-right" variant="secondary" @click="closeModal">
          İptal
        </b-button>
      </b-form>
    </b-modal>

    <!-- Test Sorgu Modal -->
    <b-modal title="Sorgu Test" hide-footer v-model="queryRunModal" size="xl">
      <b-form>
        <b-overlay
          variant="white"
          :show="show"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        ></b-overlay>
        <b-form-group label="Sorgu Anahtarı">
          <b-form-input
            v-model="kriterler.queryKey"
            placeholder="Sorgu Anahtarı"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Dönen Cevap">
          <AceEditor
            ref="aceEditor"
            v-model="queryResponse"
            height="65vh"
            block
            lang="json"
            theme="monokai"
            :options="{
              tabSize: 4,
              showPrintMargin: false,
            }"
          />
        </b-form-group>
        <b-button variant="danger" @click="sorguCalistir">Çalıştır</b-button>
        <b-button class="float-right" variant="secondary" @click="closeModal">
          İptal
        </b-button>
      </b-form>
    </b-modal>

    <!-- Sql Run modal -->
    <b-modal
      title="Sql Run Test"
      hide-footer
      v-model="queryRunTextModal"
      size="xl"
    >
      <b-form>
        <b-overlay
          variant="white"
          :show="show"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <b-form-group label="Şube Seçin">
            <b-form-select id="Şube Seçin" v-model="sqlRun.branchId" required>
              <option
                v-for="branch in branchOptions"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.branchName }}
              </option>
            </b-form-select>
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Sql Sorgusu">
                <AceEditor
                  ref="aceEditor"
                  v-model="sqlRun.queryText"
                  height="65vh"
                  block
                  lang="sql"
                  theme="monokai"
                  :options="{
                    tabSize: 4,
                    showPrintMargin: false,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Dönen Cevap">
                <AceEditor
                  ref="aceEditor"
                  v-model="queryResponse"
                  height="65vh"
                  block
                  lang="json"
                  theme="monokai"
                  :options="{
                    tabSize: 4,
                    showPrintMargin: false,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button variant="danger" @click="runSqlSend">Çalıştır</b-button>
          <b-button class="float-right" variant="secondary" @click="closeModal">
            İptal
          </b-button>
        </b-overlay>
      </b-form>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AceEditor from "vue2-ace-editor";
import "brace/theme/monokai";
import "brace/snippets/sql";
import "brace/mode/sql";
import "brace/mode/json";
import "brace/snippets/json";
export default {
  components: {
    ToastificationContent,
    AceEditor,
  },
  data() {
    return {
      show: false,
      addQueryModal: false,
      updateQueryModal: false,
      cmOptions: {
        styleActiveLine: false,
        lineNumbers: true,
        tabSize: 4,
        mode: "text/x-sql",
        lineWrapping: true,
        theme: "solarized light",
        line: true,
      },
      updateQuery: {
        key: "",
        sqlQuery: "",
        backupQuery: "",
        description: "",
        branchId: null,
        id: null,
      },
      query: {
        key: "",
        sqlQuery: "",
        backupQuery: "",
        description: "",
        branchId: null,
      },
      branchOptions: [],
      queryList: [], // Sorgu listesini burada tutun
      tableFields: [
        { key: "key", label: "Sorgu Anahtarı", sortable: true },
        { key: "sqlQuery", label: "SQL Sorgusu", sortable: false },
        { key: "description", label: "Açıklama", sortable: true },
        { key: "branchId", label: "Şube", sortable: true },
        { key: "actions", label: "İşlem" },
      ],
      queryRunModal: false,
      queryResponse: "",
      kriterler: {
        queryKey: "",
        metin1: "",
        metin2: "",
        metin3: "",
        metin4: "",
        metin5: "",
        metin6: "",
        metin7: "",
        metin8: "",
        metin9: "",
      },
      queryRunTextModal: false,
      sqlRun: {
        queryText: "",
        branchId: null,
      },
    };
  },
  created() {
    this.getBranch();
    this.getQuery();
  },
  methods: {
    async runSqlSend() {
      this.show = true;
      this.queryResponse = "";
      await this.$http
        .post("query/GetTextQueryRun", this.sqlRun)
        .then((response) => {
          this.show = false;
          this.queryResponse = response.data;
          this.$refs.aceEditor.editor.setValue(
            JSON.stringify(this.queryResponse, null, 2),
            -1
          );
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.Message,
            },
          });
        });
    },
    async runQuery() {
      this.queryRunTextModal = true;
      this.queryResponse = "";
      this.sqlRun = {
        queryText: "",
        branchId: null,
      };
    },
    async testQuery() {
      this.kriterler = {
        queryKey: "",
        metin1: "",
        metin2: "",
        metin3: "",
        metin4: "",
        metin5: "",
        metin6: "",
        metin7: "",
        metin8: "",
        metin9: "",
      };
      this.queryResponse = "";
      this.queryRunModal = true;
    },
    async sorguCalistir() {
      this.show = true;
      this.queryResponse = "";
      await this.$http
        .post("query/GetQueryRun", this.kriterler)
        .then((response) => {
          this.show = false;

          this.queryResponse = response.data;

          this.$refs.aceEditor.editor.setValue(
            JSON.stringify(this.queryResponse, null, 2),
            -1
          );
        })
        .catch((error) => {
          this.show = false;
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.Message,
            },
          });
        });
    },
    //
    async editQuery(item) {
      this.updateQuery = {
        key: item.key,
        sqlQuery: item.sqlQuery,
        backupQuery: item.backupQuery,
        description: item.description,
        branchId: item.branchId,
        id: item.id,
      };
      this.updateQueryModal = true;
    },
    async editSend() {
      this.$http
        .put("query/UpdateQuery", this.updateQuery)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Başarılı`,
              icon: "CoffeeIcon",
              variant: "success",
              text: response.data,
            },
          });
          this.show = false;
          this.updateQueryModal = false;
          this.getQuery();
        })
        .catch((error) => {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.Message,
            },
          });
        });
    },
    showAddModal() {
      this.addQueryModal = true;
      this.query = {
        key: "",
        sqlQuery: "",
        backupQuery: "",
        description: "",
        branchId: null,
      };
    },
    async queryAddSend() {
      // Yeni sorgu ekleme işlemi
      this.show = true;

      this.$http
        .post("query", this.query)
        .then((response) => {
          this.show = false;
          this.queryList.push({ ...this.query });
          this.addQueryModal = false;
          this.getQuery();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
      //  // Örnek olarak listeye ekliyoruz
    },

    deleteQuery(id) {
      this.$bvModal
        .msgBoxConfirm("Sorguyu Silmek İstediğinize Eminmisiniz ?", {
          title: "Dikkat",
          size: "sm",
          okVariant: "danger",
          okTitle: "Evet",
          cancelTitle: "Hayır",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.show = true;
            this.$http
              .delete(`query/${id}`)
              .then((response) => {
                this.queryList = this.queryList.filter((i) => i.id !== id);
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Başarılı`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `Silme İşlemi Başarılı..`,
                  },
                });
              })
              .catch((error) => {
                this.show = false;
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Hata`,
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: error.response.data.message,
                  },
                });
              });
          }
        });
    },
    //
    getBrancgTypeCode(id) {
      const type = this.branchOptions.find((s) => s.id === id);
      return type ? type.branchName : "Bilinmeyen Tip";
    },
    async getQuery() {
      this.show = true;
      this.$http
        .get("query")
        .then((response) => {
          if (response.status == 200) {
            this.show = false;
            this.queryList = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    async getBranch() {
      this.$http
        .get("branch/getbranchs")
        .then((response) => {
          if (response.status == 200) {
            this.show = false;
            this.branchOptions = response.data;
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    closeModal() {
      this.addQueryModal = false;
      this.updateQueryModal = false;
    },
  },
};
</script>

<style scoped>
.card-title {
  font-weight: bold;
}
.truncate-text {
  display: block; /* Blok düzeyinde görünüm */
  white-space: nowrap; /* Tek satırda tut */
  overflow: hidden; /* Taşan metni gizle */
  text-overflow: ellipsis; /* Fazlalığı üç nokta ile göster */
  max-width: 400px; /* Sütunun genişliğini belirleyin */
}
</style>
